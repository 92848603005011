module directives {
    export module logistics {
        interface IConsignmentImportSLAMetricsScope extends ng.IScope {
            consignmentImportSLAMetricsData: interfaces.consignment.IConsignmentSLAMetricsData[];
            isLoading: boolean;
            LoadConsignmentSLAMetricsData();
            navigateToConsignmentListView(statusDescription: string, SLAStatus: number);
        }

        export class consignmentImportSLAMetricsDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/logistics/consignmentImportSLAMetricsView.html';
            scope = {
            }

            public consignmentImportSLAMetricsData: interfaces.consignment.IConsignmentSLAMetricsData[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private consignmentService: interfaces.consignment.IConsignmentService,
            ) {
                   
            }

            link = ($scope: IConsignmentImportSLAMetricsScope, $element: ng.IAugmentedJQuery, $state) => {
                var filterList: interfaces.applicationcore.IKeyValue[] = [];
                var searchList: interfaces.applicationcore.IKeyValue[] = [];
                
                $scope.LoadConsignmentSLAMetricsData = () => {
                    $scope.isLoading = true;
                    return this.consignmentService.GetImportConsignmentSLAMetricsData().query({
                    }, (resultList) => {
                            $scope.consignmentImportSLAMetricsData = resultList;
                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.LoadConsignmentSLAMetricsData();       
                
                $scope.navigateToConsignmentListView = (statusDescription: string, SLAStatus: number ) => {
                    filterList.push({ key: "Status", value: statusDescription });
                    searchList.push({ key: "SLAStatus", value: SLAStatus })
                    this.$state.go("auth.ConsignmentImports", { FilterList: filterList, SearchList: searchList });
                }
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state,
                    bsLoadingOverlayService, consignmentService) => new consignmentImportSLAMetricsDirective(generalService, $state, bsLoadingOverlayService,consignmentService);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService","consignmentService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsImportConsignmentSla", consignmentImportSLAMetricsDirective.factory());
    }
}